@font-face {
  font-family: "CascadiaMono";
  src: url("/assets/fonts/CascadiaMono.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "CascadiaMono";
  src: url("/assets/fonts/CascadiaMonoPL-Bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
