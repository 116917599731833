.--typer-animation {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  border-right: 2px solid #fff;
  animation: typer-animation 1s infinite;
  height: 100%;
  min-height: 24px;
}

@media (max-width: 768px) {
  .--typer-animation {
    border-right: 1.5px solid #fff;
    min-height: 18px;
  }
}

@keyframes typer-animation {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
  60% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
