@tailwind components;
@tailwind base;
@tailwind utilities;

* {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Body XLarge 1 */
.bxl {
  font-size: 20px;
  font-weight: 500;
}

:root {
  --primary: #e2b677;
  --secondary: #27313f;
  --black: "#231f20";
  --red: #d92528;
  --blue: #27313f;
  --grey: #ededef;
  --white: #fff;
  color-scheme: dark !important;
}

:root {
  --background: #0e1115;
  --foreground: #1e242d;
  --accent: #2d3542;
  --entity: #59687b;
  --action: #e2b576;
  --font1: #ffffff;
  --font2: #e1e1e1;
  --font3: #afafaf;
  --font4: #878787;
  --positive: #00a04d;
  --negative: #fc2a50;
  --negative-dark: #fc2a5033;
  --warning: #ff9500;
  --notice: #02070e;
  --primary: #e2b576;
  --secondary: #fadbb0;
  --tetriary: #806135;
  --border: 1px solid rgba(255, 255, 255, 0.1);
}

:root {
  --modal-black-alpha-600: rgba(0, 0, 0, 0.48);
  --modal-z-index: 1400;
  --modal-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  --modal-radii-md: 0.375rem;
  --modal-space-2: 0.5rem;
  --modal-space-3: 0.75rem;
  --modal-space-4: 1rem;
  --modal-space-6: 1.5rem;
  --modal-space-8: 2rem;
  --modal-font-size-xl: 1.25rem;
  --modal-sizes-sm: 24rem;
  --modal-sizes-lg: 48rem;
}

:root {
  --black-base: #171b20;
  --white-base: #fbfbfb;
  --grey-80: #3a3c40;
  --grey-60: #606366;
  --grey-40: #91969d;
  --grey-20: #d2d2d2;
  --grey-10: #efefef;
  --blue-100: #1e242d;
  --blue-95: #27313f;
  --blue-80: #59687b;
  --blue-60: #8ba2c0;
  --gold-100: #e2b677;
  --gold-120: #be933e;
  --hoover: #25acd7;
  --error: #c43030;
  --deal-red: #ff2323;
  --deal-orange: #ff9a0a;
}

.modal__content {
  max-width: var(--modal-sizes-lg);
}

@media screen and (min-width: 0em) and (max-width: 47.98em) {
  .modal__content {
    max-width: var(--modal-sizes-sm);
  }
}

.show-above-md {
  display: none;
}

@media (min-width: 48rem) {
  .show-above-md {
    display: block;
  }
}

@layer base {
  h1,
  h2,
  h3,
  h4 {
    @apply !text-brand-font-two font-semibold font-sans;
  }
  p,
  body,
  footer,
  ul,
  li,
  select,
  input,
  textarea {
    @apply text-brand-white font-sans;
  }
  p {
    @apply text-brand-font-two;
  }
  body {
    @apply !bg-brand-background;
  }
  label {
    @apply !text-brand-font-four !text-label-xs;
  }
}

@layer components {
  .btn-primary {
    @apply py-2 px-3 bg-catalog-primary rounded-sm text-catalog-white;
  }
  .btn-primary:hover {
    @apply opacity-80 transition ease-in-out delay-150;
  }
  .btn-hamburger {
    @apply text-catalog-primary p-2 pr-0 rounded-lg;
  }
  .btn-secondary {
    @apply text-brand-font-one bg-brand-accent px-3 py-2 rounded-lg font-semibold text-label-sm;
  }
  .btn-success {
    @apply bg-alerts-success px-3 py-2 rounded-lg text-brand-font-one font-semibold text-label-sm;
  }
  .nav-link {
    @apply text-brand-font-two text-label-sm font-semibold hover:text-brand-font-four w-auto;
    font-size: 15px;
    color: white;
  }
  .custom-select-wrapper {
    position: relative;
  }

  .custom-select {
    @apply !bg-brand-background appearance-none;
    padding-right: 1.5rem;
  }

  .custom-select-wrapper::after {
    content: "▼";
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Figtree", sans-serif !important;
  background: var(--background);
  color: var(--font1);
}

.glass-bg {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(0px);
  -webkit-backdrop-filter: blur(0px);
  padding: 2rem;
}

.autocomplete-li:hover {
  background: var(--grey-10);
  cursor: pointer;
}

.autocomplete-li-active {
  background: var(--grey-10);
  cursor: pointer;
}

h2,
h2.ant-typography,
h3,
h3.ant-typography,
h4,
h4.ant-typography,
h5,
h5.ant-typography,
h6,
h6.ant-typography {
  color: var(--font1);
}

.ant-form-item-label > label,
.ant-layout-footer,
.ant-drawer-title,
.ant-menu-item a,
.ant-page-header-heading-title,
.ant-card-meta-title,
.ant-typography,
.ant-descriptions-item-content,
.ant-popover-inner-content {
  color: var(--font1);
}

html {
  scroll-behavior: smooth;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover {
  @apply text-brand-font-four;
}

* {
  box-sizing: border-box;
}

@import "./rows.min.css";

.d-none {
  display: none;
}
.d-block {
  display: block;
}

@media (min-width: 768px) {
  .d-md-block {
    display: block;
  }
  .d-md-inline {
    display: inline;
  }
  .d-md-none {
    display: none;
  }
}

.sidebar {
  height: 100vh;
}

.ant-layout {
  background: var(--grey) !important;
}

.ant-layout-header {
  background: var(--secondary) !important;
}

.ant-tooltip-placement-bottom {
  padding-top: 0;
  margin-top: -1rem;
}

button[disabled] {
  cursor: not-allowed;
  opacity: 0.8;
}

.input-light::-webkit-input-placeholder {
  color: var(--grey-20);
}

.input-light[type="date"]::-webkit-calendar-picker-indicator {
  background: url("/assets/icons/date.svg");
  z-index: 3;
  cursor: pointer;
}

@media (max-width: 769px) {
  .input-light[type="date"]::-webkit-calendar-picker-indicator {
    width: 20px;
    height: 20px;
  }
}

input:disabled,
select:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.ant-btn-primary {
  background: var(--primary) !important;
  border: var(--primary) !important;
  color: var(--white) !important;
}

.ant-tag-red {
  background: var(--blue) !important;
  border-color: var(--grey) !important;
  color: var(--white) !important;
}

.ant-pagination-item-active,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-item:hover,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-input:focus,
.ant-picker-focused,
.ant-input:hover,
.ant-picker:hover,
.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item {
  border-color: var(--primary) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: var(--primary) !important;
}

.anticon.anticon-ellipsis,
.ant-pagination-item-active a,
.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-item:hover a,
.ant-pagination-jump-next
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-jump-prev
  .ant-pagination-item-container
  .ant-pagination-item-link-icon,
.ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow,
.anticon .anticon-info-circle .ant-tooltip-open,
.anticon.anticon-ellipsis,
.ant-btn-link,
.ant-picker-today-btn {
  color: var(--primary) !important;
}

#cart-button.ant-btn-link {
  color: gray !important;
}

.ant-menu.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark.ant-menu-horizontal > .ant-menu-item:hover,
.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
  background: var(--primary) !important;
}

/* DARK BLUE CSS OVERWRITES */
.ant-menu.ant-menu-dark {
  background: var(--blue) !important;
}

.logo {
  height: 32px;
  margin-left: 1rem;
}

.absolute-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-tabs-tab {
  white-space: normal;
  word-break: break-word;
  text-align: left !important;
}

.preview-video {
  height: 100%;
}

.ant-btn.ant-btn-ghost,
.ant-btn.ant-btn-ghost.ant-btn-icon-only.ant-dropdown-trigger {
  border: none;
  padding: 0;
  height: 40px;
}

.animated-gradient {
  font-size: 48px;
  font-weight: 700;
  text-shadow: rgb(0 0 0 / 10%) 2px 2px 3px;
  background-image: -webkit-linear-gradient(45deg, #e4b777, #778ee4);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation: hue 10s linear infinite;
  animation: hue 10s linear infinite;
  text-align: center;
  line-height: 1.1;
}

.iframe-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.iframe-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ant-btn.ant-btn-primary.added {
  background: orange;
  border: orange;
}

input[type="date"] {
  min-height: 26px;
}

.disabled {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.ant-form-item {
  margin: 0 4px 4px 4px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
  transform: translateY(-100%) translateX(-10%);
}

.w-100,
.ant-form-item .ant-form-item-label > label,
.ant-form-horizontal .ant-form-item-control {
  width: 100%;
}

.ant-col.ant-col-16.ant-form-item-control {
  max-width: 100%;
}

/* FORM STYLING */
.ant-form-inline .ant-form-item {
  margin-right: 0;
}
form.ant-form-inline .ant-row {
  margin: 0;
}

#nest-messages label.ant-form-item-required {
  display: none;
}

.ant-popover-placement-bottomRight .ant-popover-arrow {
  right: 92px;
}

.ant-popover-placement-bottomRight {
  margin-top: -3.25rem;
}

.ant-popover-inner {
  margin-top: -1.45rem;
}

.ant-layout-header {
  position: sticky;
  width: 100%;
  left: 0;
  top: 0;
  right: 0;
  height: 64px;
  z-index: 1000;
}

.ant-popover-placement-bottomRight .ant-popover-arrow {
  top: 1px;
}

.grid {
  display: grid;
}

.gap-4 {
  grid-gap: 1rem;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.mt-5 {
  margin-top: 1.25rem;
}

.ant-popover {
  position: fixed;
  z-index: 1000;
}

/* CUSTOM LANDSCAPE MOBILE */
@media (max-width: 575px) {
  #next-messages .ant-form .ant-form-item .ant-form-item-label {
    max-width: 0%;
  }
  #next-messages .ant-col.ant-col-16.ant-form-item-control {
    max-width: 100%;
  }
  #page-layout-header
    ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-dark {
    height: 48px;
  }
  /* .ant-popover-inner {
    margin-right: 0.45rem;
  } */
  .ant-layout-footer {
    padding-right: 1rem;
  }
  #shopping-cart-remove-button {
    position: absolute;
    right: -160px;
    top: -35px;
  }
  #contact-us-mobile {
    margin-left: 1rem;
    padding-left: 0.45rem;
    padding-right: 0.45rem;
    margin-right: -8px;
  }
}

/* CUSTOM < TABLET SCREEN SIZE */
@media (max-width: 769px) {
  .navbar {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  #next-messages .ant-col.ant-col-16.ant-form-item-control,
  #next-messages .ant-form .ant-form-item .ant-form-item-label {
    width: 100%;
  }
}

/* CUSTOM > TABLET SCREEN SIZE */
@media (min-width: 769px) {
  form .ant-space-item {
    margin-top: 1rem;
  }

  .ant-tabs-left
    > .ant-tabs-content-holder
    > .ant-tabs-content
    > .ant-tabs-tabpane {
    padding: 0 4px;
  }
  .ant-tabs-tab {
    padding: 0 4px !important;
  }
  .ant-tabs-left > .ant-tabs-nav.ant-tabs-tab {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .ant-popover-placement-bottomRight .ant-popover-arrow {
    right: 2px;
  }
  .md-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .footer-copy {
    margin-left: 1rem;
  }
  .ant-layout-footer .container {
    padding-right: 0;
  }
}

/* ANTD > LG SCREEN SIZE(992px) */
@media (min-width: 992px) {
  #mobile-menu-button {
    display: none;
  }
  .navbar .menu {
    display: none;
  }
  .d-lg-none {
    display: none;
  }
  .d-lg-block {
    display: block;
  }
  .ant-tabs-nav-list .ant-tabs-tab {
    font-size: 80%;
  }
  .ant-tabs-nav {
    max-width: 35%;
  }
  .ant-tabs-tab {
    padding-left: 4px !important;
    padding-right: 4px !important;
  }
  .lg-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .ant-layout-footer .container {
    padding-right: 1rem;
  }
}

/* ANTD < LG SCREEN SIZE(992px) */
@media screen and (max-width: 992px) {
  .column {
    flex: 100% !important; /* !important so that it overrides the inline style because by default it has higher priority */
  }
}

/* TailwindCSS breakpoint LG (1024px) */
@media (min-width: 1024px) {
  .lg-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

/* CUSTOM > LG SCREEN SIZE (1025px) */
@media (min-width: 1025px) {
  form.ant-form-inline .ant-row .ant-col:first-child,
  form.ant-form-inline .ant-row .ant-col:nth-child {
    padding-left: 0 !important;
  }
}
@media (max-width: 1025px) {
  form.ant-form-inline .ant-row .ant-col:nth-child(even) {
    padding-right: 0 !important;
  }
  form.ant-form-inline .ant-row .ant-col:nth-child(odd) {
    /* padding-right: 16px !important; */
    padding-left: 0 !important;
  }
}
@media (min-width: 1025px) and (max-width: 1279px) {
  form.ant-form-inline .ant-row .ant-col:nth-child(4) {
    padding-right: 0 !important;
  }
}

/* ANTD > XL SCREEN SIZE(1200px) */
@media (min-width: 1200px) {
  form.ant-form-inline .ant-row .ant-col:last-child {
    padding-right: 0 !important;
  }
}

/* TailwindCSS breakpoint XL (1280px) */
@media (min-width: 1280px) {
  .xl-grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .ant-layout-footer .container {
    padding-right: 2rem;
  }
}

/* SHOWS STYLING */
#shows-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  /* grid-template-columns: repeat(auto-fit, minmax(256px, 1fr)); */
}
@media (min-width: 481px) {
  #shows-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  #shows-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 992px) {
  #shows-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

#shows-menu.ant-menu-inline.ant-menu-root .ant-menu-item {
  height: 170px;
  width: 100%;
}

#shows-menu.ant-menu::before {
  display: none;
}

#shows-menu.ant-menu-inline.ant-menu-root.ant-menu-item {
  padding: 0;
}

/* CHOREO STYLING */
#choreo-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  display: grid;
  column-gap: 1rem;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  /* grid-template-columns: repeat(auto-fit, minmax(384px, 1fr)); */
}
@media (min-width: 481px) {
  #choreo-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}
@media (min-width: 768px) {
  #choreo-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
@media (min-width: 992px) {
  #choreo-menu.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}

#choreo-menu.ant-menu-inline.ant-menu-root .ant-menu-item {
  height: 216px;
  width: 100%;
}

#choreo-menu.ant-menu::before {
  display: none;
}

/* trick for hiding loading spinner on html5 video */

video::-webkit-media-controls {
  visibility: hidden;
}

video::-webkit-media-controls-enclosure {
  visibility: visible;
}

.ant-list-item-action {
  margin-left: 12px;
}

.container {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 0px) {
  .container {
    padding-right: 13px;
    padding-left: 13px;
  }
  .container-left {
    width: auto;
  }
}

@media (min-width: 476px) {
  .container {
    width: 100%;
  }
  .container-left {
    width: auto;
  }
}
@media (min-width: 768px) {
  .container {
    width: 720px;
    max-width: 100%;
  }
  .container-left {
    width: auto !important;
    padding-right: 0;
    margin-right: 0;
    margin-left: calc((100% - 720px + 26px) / 2);
  }
  .container-blob {
    min-width: calc((100% - 720px + 26px) / 2);
  }
}
@media (min-width: 992px) {
  .container {
    width: 960px;
    max-width: 100%;
  }
  .container-left {
    width: auto;
    padding-right: 0;
    margin-right: 0;
    margin-left: calc((100% - 960px + 26px) / 2);
  }
  .container-blob {
    min-width: calc((100% - 960px + 26px) / 2);
  }
}
@media (min-width: 1200px) {
  .container {
    width: 1140px;
    max-width: 100%;
  }
  .container-left {
    width: auto;
    padding-right: 0;
    margin-right: 0;
    margin-left: calc((100% - 1140px + 26px) / 2);
  }
  .container-blob {
    min-width: calc((100% - 1140px + 26px) / 2);
  }
}
@media (min-width: 1400px) {
  .container {
    width: 1340px;
    max-width: 100%;
  }
  .container-left {
    width: auto;
    padding-right: 0;
    margin-right: 0;
    margin-left: calc((100% - 1340px) / 2);
  }
  .container-blob {
    min-width: calc((100% - 1340px) / 2);
  }
}
.container > .container {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.footer-copy {
  font-size: 80%;
  text-align: right;
}

.--ring {
  display: inline-block;
  position: relative;
  width: 32px;
  height: 32px;
}

.--ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 24px;
  height: 24px;
  margin: 3px;
  border: 3px solid #fff;
  border-radius: 50%;
  animation: ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.--ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.--ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.--ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.--relative {
  position: relative;
}

.--loading {
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(5px);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;
}

.--loading:hover {
  opacity: 1;
}

.ratio-16x9 {
  position: relative;
  padding-top: 56.25%;
  width: 100%;
}

.ratio-16x9-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* MODAL STYLING */
/* .chakra-modal__overlay {
  background: rgba(0, 0, 0, 0.5);
}

.chakra-modal__body {
  margin: 3rem auto;
  height: 992px;
  width: 85% !important;
}

.chakra-modal__body iframe {
  height: auto;
} */

footer a {
  color: #fff;
}

@keyframes shimmer {
  0% {
    background-position: -468px;
  }
  100% {
    background-position: 468px;
  }
}
.--shimmer {
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: #1e242d;
  background-image: linear-gradient(
    to right,
    rgba(45, 53, 66, 0.4) 8%,
    rgba(45, 53, 66, 0.7) 38%,
    rgba(45, 53, 66, 1) 54%
  );
  background-size: 1000px 640px;
  position: relative;
}

.--no-scrollbar::-webkit-scrollbar {
  display: none;
}
.--no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.--icon {
  background-size: "cover" !important;
  mask-size: cover !important;
  -webkit-mask-size: cover !important;
  display: inline-block;
}

.--smooth {
  transition: all 50ms ease-in-out !important;
}

.--smoother {
  transition: all 1s ease-in-out !important;
}

input {
  accent-color: var(--font3) !important;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

@media print {
  @page {
    size: 993px calc(993px * 1.414) !important;
    margin: 0;
    page-break-inside: auto;
    color-scheme: dark !important;
    background-color: var(--background);
    color: var(--font1);
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;
  }

  .container {
    width: 100% !important;
    max-width: unset !important;
    padding-left: 24px;
    padding-right: 24px;
    color-scheme: dark !important;
  }

  html,
  body {
    background-color: var(--background);
    height: auto;
    overflow: auto;
    print-color-adjust: exact;
    -webkit-print-color-adjust: exact;
  }
  #beacon-container {
    display: none !important;
  }
  #scroll-to-top {
    display: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
  background-color: transparent !important;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Figtree", sans-serif !important;
  background: var(--background);
  color: var(--font1);
}

.--tip {
  display: none !important;
  overflow: visible;
}

.tip {
  overflow: visible;
}

.tip.--top-right .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0, 0, 0, 0.85);
  position: absolute;
  top: -5px;
  right: 15px;
}

.tip.--top-left .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0, 0, 0, 0.85);
  position: absolute;
  top: -5px;
  left: 15px;
}

.tip.--top-center .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(0, 0, 0, 0.85);
  position: absolute;
  top: -5px;
  left: calc(50% - 2.5px);
}

.tip.--bottom-right .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.85);
  position: absolute;
  bottom: -5px;
  right: 15px;
}

.tip.--bottom-left .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.85);
  position: absolute;
  bottom: -5px;
  left: 15px;
}

.tip.--bottom-center .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.85);
  position: absolute;
  bottom: -5px;
  left: calc(50% - 2.5px);
}

.tip:hover .--tip {
  display: block !important;
}

.--dark-mode-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 3px;
  border-radius: 10px;
  overflow: hidden;
}
.--dark-mode-scrollbar::-webkit-scrollbar-button {
  background-color: var(--accent);
}
.--dark-mode-scrollbar::-webkit-scrollbar-track {
  background-color: var(--accent);
  border-radius: 10px;
  overflow: hidden;
}
.--dark-mode-scrollbar::-webkit-scrollbar-track-piece {
  background-color: var(--accent);
}
.--dark-mode-scrollbar::-webkit-scrollbar-thumb {
  height: 50px;
  background-color: var(--entity);
  border-radius: 10px;
  overflow: hidden;
}
.--dark-mode-scrollbar::-webkit-scrollbar-corner {
  background-color: var(--accent);
  border-radius: 10px;
  overflow: hidden;
}
.--dark-mode-scrollbar::-webkit-resizer {
  background-color: var(--accent);
  border-radius: 10px;
  overflow: hidden;
}

.--cms a {
  color: var(--primary);
  font-weight: 500;
  text-decoration: underline;
}

.--typer {
  transition: all 0.2s ease-in-out !important;
  cursor: pointer;
}

.--typer:hover {
  color: var(--primary);
}

.--tip {
  display: none !important;
  overflow: visible;
}

.tip {
  overflow: visible;
}

.tip.--top-right .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(68, 68, 68, 0.85);
  position: absolute;
  top: -5px;
  right: 15px;
}

.tip.--top-left .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(68, 68, 68, 0.85);
  position: absolute;
  top: -5px;
  left: 15px;
}

.tip.--top-center .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid rgba(68, 68, 68, 0.85);
  position: absolute;
  top: -5px;
  left: calc(50% - 2.5px);
}

.tip.--bottom-right .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(68, 68, 68, 0.85);
  position: absolute;
  bottom: -5px;
  right: 15px;
}

.tip.--bottom-left .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(68, 68, 68, 0.85);
  position: absolute;
  bottom: -5px;
  left: 15px;
}

.tip.--bottom-center .--tip::before {
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(68, 68, 68, 0.85);
  position: absolute;
  bottom: -5px;
  left: calc(50% - 2.5px);
}

.tip:hover .--tip {
  display: block !important;
  z-index: 999 !important;
}

.--icon {
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-mask-size: cover;
  mask-size: cover;
  display: inline-block;
}

.stage-player-editor-main-wrapper .--children {
  opacity: 0 !important;
  cursor: pointer;
}

.stage-player-editor-main-wrapper .--parent:hover > .--children {
  opacity: 1 !important;
}

.stage-player-editor-main-wrapper select.--select {
  height: 32px;
  width: 100%;
  display: block;
  border-radius: 5px;
  border: 1px solid #2c2c2c;
  color: white;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  background: transparent;
  line-height: 32px;
  box-sizing: border-box;
  padding: 0 10px;
  outline: none !important;
  margin-bottom: 0 !important;
  font-family: Figtree;
}

.stage-player-editor-main-wrapper select.--select:disabled {
  opacity: 0.5;
}

.stage-player-editor-main-wrapper textarea.--textarea {
  height: 64px;
  width: 100%;
  display: block;
  border-radius: 5px;
  border: 1px solid #2c2c2c;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  background: transparent;
  box-sizing: border-box;
  padding: 10px;
  outline: none !important;
  margin-bottom: 0 !important;
  font-family: Figtree;
  resize: none;
}

.stage-player-editor-main-wrapper input.--small {
  height: 24px;
  width: 100%;
  display: block;
  border-radius: 5px;
  border: 1px solid #2c2c2c;
  color: white;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  background: transparent;
  box-sizing: border-box;
  padding: 5px;
  outline: none !important;
  margin-bottom: 0 !important;
  font-family: Figtree;
  resize: none;
}

.stage-player-editor-main-wrapper textarea.--textarea:disabled {
  opacity: 0.5;
}

.stage-player-editor-main-wrapper input.--range {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  background: #444444;
  outline: none;
  border-radius: 999px;
}

.stage-player-editor-main-wrapper input.--range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  border-radius: 999px;
  background: #e2b576;
  cursor: pointer;
}

*.--blue-gradient-text {
  background-image: linear-gradient(90deg, #46d3f2, #1875ff) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  background-clip: text !important;
}

.top-0 {
  top: 0;
}
.left-0 {
  top: 0;
}
.w-100 {
  width: 100%;
}
.h-100 {
  height: 100%;
}
.position-absolute {
  position: absolute;
}
.position-relative {
  position: relative;
}

*,
body,
html,
#__next {
  scroll-behavior: smooth !important;
}

.--underline {
  text-decoration: underline !important;
}

.lite-enter {
  opacity: 0;
  z-index: 3;
}

.lite-enter-active {
  opacity: 1;
  transition: opacity 2s;
  z-index: 3;
}

.lite-exit {
  opacity: 1;
  z-index: 1;
}

.lite-exit-active {
  opacity: 0;
  transition: opacity 2s;
  z-index: 3;
}

.old9d29cf7b-enter {
  opacity: 0;
  z-index: 3;
}

.old9d29cf7b-enter-active {
  opacity: 1;
  transition: opacity 2s;
  z-index: 3;
}

.old9d29cf7b-exit {
  z-index: 1;
}

.old9d29cf7b-exit-active {
  z-index: 1;
}

.new63e9ffd5-enter {
  opacity: 0;
  z-index: 1;
}

.new63e9ffd5-enter-active {
  opacity: 1;
  z-index: 1;
  transition: opacity 2000ms cubic-bezier(0, 1, 0, 1);
}

.new63e9ffd5-exit {
  opacity: 1;
  z-index: 3;
}

.new63e9ffd5-exit-active {
  opacity: 0;
  transition: opacity 1.8s cubic-bezier(0.5, 0, 1, 0.5);
  transition-delay: 0.2s;
  z-index: 3;
}

/**
transition: opacity 2s cubic-bezier(0, 1, 0, 1);
transition: opacity 1s cubic-bezier(0.8, 0, 1, 0.5);
*/

.full-image-important {
  width: 100% !important;
  height: 100% !important;
}

kbd {
  background-color: #eee;
  border-radius: 3px;
  border: 1px solid #b4b4b4;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.2),
    0 2px 0 0 rgba(255, 255, 255, 0.7) inset;
  color: #333;
  display: inline-block;
  font-size: 0.85em;
  font-weight: 700;
  line-height: 1;
  padding: 2px 4px;
  white-space: nowrap;
}

.--spinner {
  animation: spin 1s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg);
  }
  to {
    transform: scale(1) rotate(360deg);
  }
}

iframe.print-helper {
  position: fixed;
  top: -9999px;
  left: -9999px;
}

.ce-block__content {
  max-width: unset !important;
  color: black;
}

.ce-block__content > * {
  color: unset !important;
  opacity: unset !important;
}

.codex-editor__redactor {
  padding-bottom: 0 !important;
}

.cdx-input.embed-tool__caption {
  display: none;
}

.cdx-input.image-tool__caption:empty {
  display: none;
}

.image-tool__image-picture {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 5px;
}

.ce-paragraph.cdx-block {
  font-size: 17px;
}

.ce-block__content h1 {
  font-size: 40px !important;
  font-weight: 700;
}

.ce-block__content h2 {
  font-size: 32px !important;
  font-weight: 600;
}

.ce-block__content h3 {
  font-size: 28px !important;
  font-weight: 600;
}

.ce-block__content h4 {
  font-size: 24px !important;
  font-weight: 600;
}

.ce-block__content h5 {
  font-size: 20px !important;
  font-weight: 600;
}

.ce-block__content h6 {
  font-size: 16px !important;
  font-weight: 600;
}

.cdx-block.image-tool {
  max-width: 624px;
  margin-left: auto;
  margin-right: auto;
}

.ce-block__content a[href] {
  color: var(--tetriary) !important;
}

.ce-block:first-child > .ce-block__content > .ce-paragraph.cdx-block:empty {
  display: none !important;
}

.navbar-logo-image {
  width: 92.5px;
  height: 50px;
}

@media (max-width: 1200px) {
  .navbar-logo-image {
    width: 60px;
    height: 32px;
  }
}

.responsive-radius-image {
  display: block !important;
  width: 100% !important;
  height: auto !important;
  border-radius: 10px !important;
}

.ovation-card {
  position: relative;
  padding-top: 56.25%;
  background: linear-gradient(
    293.56deg,
    #73460e 4.49%,
    #855418 48.27%,
    #905c1d 97.64%
  );
  overflow: hidden;
  border-radius: 15px;
}

.ovation-card-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("/assets/ovation/glow.svg");
  background-size: cover;
  background-position: top left;
}

.ovation-line {
  height: 70px;
  border-radius: 15px;
  padding: 0 15px;
  background: linear-gradient(
    293.56deg,
    #73460e 4.49%,
    #855418 48.27%,
    #905c1d 97.64%
  );
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img.height-auto {
  height: auto;
  display: block;
}
