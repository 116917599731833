.home-search {
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 999;
  background: transparent;

  * {
    z-index: 1;
  }

  h1 {
    font-size: 32px;
    line-height: 32px;
    color: #fff !important;
    margin-top: 15px;
    margin-bottom: 24px;
    font-weight: 700;
  }

  h2 {
    color: var(--white-base) !important;
    display: inline-block;
    max-width: 851px;
    text-align: center;
    margin-bottom: 30px;
    padding: 0 30px;
  }

  .search {
    display: flex;
    padding: 0 30px;
    margin-bottom: 40px;

    input {
      min-width: 278px;

      &::placeholder {
        color: var(--grey-60);
      }
    }
  }

  @media (min-width: 1024px) {
    h1 {
      font-size: 60px;
      line-height: 60px;
      margin-top: 40px;
    }

    .search {
      input {
        min-width: 407px;
      }
    }
  }

  @media (min-width: 1025px) {
    h1 {
      font-size: 96px;
      line-height: 96px;
      margin-top: 70px;
    }

    h2 {
      padding: 0;
      margin-bottom: 56px;
    }
  }
}

.pulse-link {
  position: absolute;
  display: flex;
  column-gap: 20px;
  cursor: pointer;

  .pulse-link-dot {
    height: 24px;
    border: 1px solid #fff;
    padding: 4px;
    border-radius: 50%;
    animation: pulse 1.5s infinite;
    animation-play-state: running;

    &:hover {
      opacity: 1 !important;
      transition: opacity 0.3s ease;
      animation-play-state: paused;
    }

    div {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  a,
  span,
  div {
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.85) !important;
  }
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.5;
  }
}

.mobile {
  .c\:\?background {
    color: var(--background) !important;
  }

  .c\:\?foreground {
    color: var(--foreground) !important;
  }

  .c\:\?accent {
    color: var(--accent) !important;
  }

  .c\:\?entity {
    color: var(--entity) !important;
  }

  .c\:\?font1 {
    color: var(--font1) !important;
  }

  .c\:\?font2 {
    color: var(--font2) !important;
  }

  .c\:\?font3 {
    color: var(--font3) !important;
  }

  .c\:\?font4 {
    color: var(--font4) !important;
  }

  .c\:\?positive {
    color: var(--positive) !important;
  }

  .c\:\?negative {
    color: var(--negative) !important;
  }

  .c\:\?primary {
    color: var(--primary) !important;
  }

  .c\:\?secondary {
    color: var(--secondary) !important;
  }

  .\!dl,
  .\!dm,
  .\!ds,
  .\!dt,
  .\!hl,
  .\!hm,
  .\!hs,
  .\!ht,
  .\!pl,
  .\!pm,
  .\!ps,
  .\!pt,
  .\!ll,
  .\!lm,
  .\!ls,
  .\!lt,
  .\!_t,
  .\!_p,
  .\!_d {
    all: unset;
    color: var(--font1);
    font-family: Figtree, sans-serif;
  }

  .\!dl {
    font-size: 48px;
    font-weight: 600;
    line-height: 64px;
    display: block;
  }

  .\!dm {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    display: block;
  }

  .\!ds {
    font-size: 28px;
    font-weight: 600;
    line-height: 32px;
    display: block;
  }

  .\!dt {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    display: block;
  }

  .\!hl {
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
    display: block;
  }

  .\!hm {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    display: block;
  }

  .\!hs {
    font-size: 19px;
    font-weight: 600;
    line-height: 24px;
    display: block;
  }

  .\!ht {
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
    display: block;
  }

  .\!pl {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    display: block;
  }

  .\!pm {
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    display: block;
  }

  .\!ps {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    display: block;
  }

  .\!pt {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    display: block;
  }

  .\!ll {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }

  .\!lm {
    font-size: 15px;
    font-weight: 600;
    line-height: 18px;
  }

  .\!ls {
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }

  .\!lt {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
  }

  .\!_t {
    font-size: 24px;
    font-weight: 600;
    line-height: 40px;
  }

  .\!_p {
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
  }

  .\!_d {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 400;
  }

  .\@section {
    padding: 32px 0;
    z-index: 1;
    position: relative;
  }

  .mb\:32 {
    margin-bottom: 32px !important;
  }

  .mb\:24 {
    margin-bottom: 24px !important;
  }

  .mb\:16 {
    margin-bottom: 16px !important;
  }

  .pb\:32vw {
    padding-bottom: 32vw !important;
  }

  .\@grid-step-scroller {
    display: flex;
    margin: 0 -15px;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding: 0 30px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    &_item {
      &_256 {
        width: 256px;
        margin-right: 15px;
        flex-shrink: 0;
      }

      &_100\% {
        width: calc(100vw - 45px);
        margin-right: 15px;
        flex-shrink: 0;
      }
    }
  }

  @media only screen and (width > 800px) {
    .\@grid-step-scroller {
      &::-webkit-scrollbar {
        display: none;
      }

      &_item {
        &_100\% {
          width: calc(50vw - 45px) !important;
        }
      }
    }
  }

  .\@card {
    &_foreground {
      background-image: unset !important;
      background-color: var(--foreground) !important;
    }

    &\:16x9 {
      padding-top: 56.25%;
      width: auto;
      position: relative;
      border-radius: 5px;
      background-image: url(/assets/section/placeholder.webp);
      background-size: cover;

      &_image {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 90%;
        height: 90%;
        object-fit: contain;
      }
    }
  }

  .\@card\:plain {
    padding: 20px;
    border-radius: 5px;
    background-color: #1a1e23;
  }

  .ta\:center {
    text-align: center !important;
  }

  #testimonials {
    background-color: var(--background);
    background-image: url(/assets/section/stage-people.webp);
    background-blend-mode: luminosity;
    background-size: cover;
    background-position: top center;
  }

  .\@double-gradient-card {
    padding-top: 60%;
    border-radius: 5px;
    background-image: linear-gradient(145deg, black, var(--foreground));
    background-size: cover;
    background-position: center;
    position: relative;
    overflow: hidden;

    &_1x1 {
      padding-top: 100% !important;
    }

    &::before {
      content: attr(title);
      display: block;
      position: absolute;
      bottom: 20px;
      left: 20px;
      font-size: 18px;
      font-family: Figtree, sans-serif;
      font-weight: 600;
      line-height: 20px;
      color: white;
      opacity: 0.8;
      z-index: 2;
    }

    &::after {
      content: " ";
      display: block;
      position: absolute;
      bottom: 20px;
      right: 20px;
      width: 40px;
      height: 40px;
      background-image: url(/assets/icons/arrow-right.svg);
      background-size: contain;
      z-index: 2;
    }

    &_purple-gradient {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;

      &::before {
        content: " ";
        display: block;
        background-color: rgb(110, 30, 184);
        width: 178px;
        height: 188px;
        border-radius: 999px;
        top: 52px;
        left: -112px;
        filter: blur(58.2979px);
      }

      &::after {
        content: " ";
        display: block;
        width: 415px;
        height: 274px;
        border-radius: 999px;
        top: -90px;
        left: -80px;
        z-index: 1;
        filter: blur(58.2979px);
        background-image: linear-gradient(
          to right,
          rgb(36, 11, 163),
          transparent
        );
      }
    }

    &_image {
      width: 40%;
      height: 100%;
      object-fit: contain;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
    }
  }

  .d\:block {
    display: block !important;
  }

  .\@iframe {
    width: 100%;
    position: relative;
    padding-top: 56.25%;
    border-radius: 5px;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 5px;
    }
  }

  .--blue-gradient-text {
    background-image: linear-gradient(
      90deg,
      #46d3f2,
      #1875ff,
      #46d3f2
    ) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
    background-clip: text !important;
    background-size: 100%;
  }

  .p\:relative {
    position: relative !important;
  }

  .d\:flex {
    display: flex !important;
  }

  .a\:center {
    align-items: center !important;
  }

  .j\:center {
    justify-content: center !important;
  }

  .ml\:6 {
    margin-left: 6px !important;
  }

  .\@blog {
    border-radius: 5px;
    background-color: #171b20;
    overflow: hidden;
    position: relative;
    &_header {
      width: 100%;
      height: 200px;
      position: relative;
    }
    &_image_container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 200px;
    }
    &_image {
      object-fit: cover;
      object-position: top;
      cursor: pointer;
    }
    &_body {
      padding: 20px;
    }
  }

  .max-line-3 {
    overflow: hidden !important;
    display: -webkit-box !important;
    -webkit-line-clamp: 3 !important;
    -webkit-box-orient: vertical !important;
    line-clamp: 3 !important;
  }

  .\@slanted {
    padding-top: 64px;
    z-index: 1;
    overflow: visible;
    position: relative;

    &_instagram {
      height: calc(100% + 64px);
      z-index: 1;
      top: -64px;
      position: absolute;
      left: 0;
      display: block;
      width: 100%;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: " ";
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        clip-path: polygon(0px 0px, 100% 64px, 100% 100%, 0px 100%);
        background-image: linear-gradient(
          115deg,
          rgb(143, 123, 234),
          rgb(176, 105, 232),
          rgb(219, 84, 228),
          rgb(226, 115, 181),
          rgb(236, 161, 114),
          rgb(236, 136, 99),
          rgb(209, 98, 139),
          rgb(222, 126, 121),
          rgb(237, 216, 161)
        );
      }
    }

    &_content {
      position: relative;
      z-index: 3;
    }

    &_default {
      height: calc(100% + 64px);
      z-index: 1;
      top: -64px;
      position: absolute;
      left: 0;
      display: block;
      width: 100%;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        content: " ";
        display: block;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        clip-path: polygon(0px 0px, 100% 64px, 100% 100%, 0px 100%);
        background-image: linear-gradient(
          to bottom,
          var(--foreground),
          var(--background)
        );
      }
    }
  }

  .full-width-image {
    display: block;
    width: 100%;
    height: auto;
    display: block;
    pointer-events: none;
  }

  .\@slide {
    min-height: 500px;
    position: relative;
    background-position: top center;
    background-size: cover;

    &_content {
      position: relative;
      padding-top: 96px;
      height: calc(500px - 96px - 64px);
      z-index: 2;

      &_logo {
        width: 256;
        height: auto !important;
        display: block;
        margin-bottom: 16px;
      }
    }

    &::before {
      content: " ";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-image: url(/assets/hero/homepage-gradient-webp.webp);
      z-index: 1;
    }

    &_controls {
      position: absolute;
      right: 15px;
      bottom: 64px;
      display: inline-flex;
      align-items: center;
      z-index: 3;

      &_current_label {
        display: block;
        color: white;
        font-size: 24px;
        font-family: Figtree, sans-serif;
        font-weight: 600;
        line-height: 16px;
        flex-shrink: 0;
      }

      &_next_label {
        display: block;
        opacity: 0.5;
        color: white;
        font-size: 16px;
        font-family: Figtree, sans-serif;
        font-weight: 600;
        line-height: 16px;
        flex-shrink: 0;
      }

      &_prev {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        background-image: url(/assets/icons/slide-left.svg);
        cursor: pointer;
        margin-left: 12px;
      }

      &_next {
        width: 32px;
        height: 32px;
        flex-shrink: 0;
        background-image: url(/assets/icons/slide-right.svg);
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }
}
